/**
 * Generates a checksum based on the input, which can be a string, object, or array.
 * The checksum is a hexadecimal string representation calculated from the JSON string
 * of the input. The checksum value is between 0 and 65535 (inclusive), modulo 65536.
 *
 * @param {any} input - The input string, object, or array to generate a checksum for.
 * @returns {string} The checksum as a hexadecimal string, ranging from 1 to 4 characters.
 */
export default (input: any): string => {
  const jsonString = JSON.stringify(input)
  let checksum = 0
  for (let i = 0; i < jsonString.length; i++) {
    checksum = (checksum + jsonString.charCodeAt(i)) % 65536
  }
  return checksum.toString(16)
}
